import React from 'react';
import { Button } from '@components/Button';

import { DEFAULT_DESIGN_PRICE } from '@consts/designConsts';
import styles from './PriceCard.module.scss';

interface PriceCardArgs {
  scrollToTarget: () => void;
}

export function PriceCard({ scrollToTarget }: PriceCardArgs) {
  return (
    <div className={styles.blockBuildCard}>
      <div className={styles.buildCard}>
        <div className={styles.contentCard}>
          <div className={styles.leftSide}>
            <div className={styles.price}>{DEFAULT_DESIGN_PRICE} ₽</div>
            <div className={styles.description}>
              Стоимость
              <br />
              сборки любой фотокниги
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              onClick={scrollToTarget}
              title="Оформить услугу"
              customClassName={styles.margin}
            />
            <Button
              title="Задать вопрос менеджеру"
              link="https://wa.me/79686043008?text=Здравствуйте! Хочу оформить заявку на услугу «Соберем фотокнигу за вас»"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
